





















































































































































import { Mixin_list } from "@/mixin";
// import { api_city, city } from "@/http/city";
import { api_goods, goods } from "@/http/goods";
import { Component } from "vue-property-decorator";
import Page from "@/components/page/index.vue";
import Table from "@/components/table/index.vue";
import DatePicker from "@/components/datePicker/index.vue";
import { api_goodsCategory, goodsCategory } from "@/http/goodsCategory";

@Component({
  components: {
    Page,
    Table,
    DatePicker,
  },
})
export default class extends Mixin_list<goods>(api_goods.get_list) {
  filter = {
    update_time_after: "",
    update_time_before: "",
    name: "",
    id: "",
  };
  resetFilter = {
    ...this.filter,
  };
  columns = [
    {
      label: "信息",
      slot: "info",
      prop: "info",
    },
    {
      label: "商品分类",
      prop: "type_clothing",
    },
    {
      label: "价格（元）",
      prop: "price",
      width: 200,
    },
    {
      label: "已售/库存",
      slot: "salestock",
      prop: "salestock",
      width: 300,
    },
    {
      label: "状态",
      prop: "status",
      slot: "status",
      width: 120,
    },
    {
      label: "上架时间",
      prop: "update_time",
      width: 300,
    },
    {
      label: "排序号",
      prop: "sort",
      width: 100,
    },
    {
      label: "操作",
      prop: "",
      slot: "operation",
    },
  ];
  getImageUrl(cover: string) {
    if (cover && typeof cover === "string") {
      // 将字符串按逗号分割成数组
      const coverIds = cover.split(",");
      // 取数组的第一个元素作为图片ID
      const coverId = coverIds[0].trim();
      // 拼接图片URL
      return `https://images.xiyuebiaoju.com/${coverId}`;
    }
  }
  status(status: number) {
    switch (status) {
      case 1:
        return "已上架";
      case 0:
        return "已下架";
    }
  }
  address(data: any) {
    return `${data.province}${data.city}${data.region}${data.address}`;
  }
  onUpdateRange([startStr, endStr]: [string, string]) {
    this.filter.update_time_after = startStr;
    this.filter.update_time_before = endStr;
  }
  reset() {
    this.filter = { ...this.filter, ...this.resetFilter };
    (this.$refs.datePicker as Vue & { clearDates: () => void }).clearDates();
    this.find();
  }

  async handleClick(type: string, row: any) {
    switch (type) {
      case "status":
        await api_goods.edit({ ...row, status: row.status === 0 ? 1 : 0 });
        this.get_list();
        return;
    }
  }

  created() {
    // this.get_city_list();
  }

  click_cell(row: goods) {
    return this.$router.push(`/goods/edit/?id=${row.id}`);
  }
  async del(id: number) {
    await api_goods.del_one(id);
    this.get_list();
  }
//   async edit(id: number) {
//     await api_goods.edit(id);
//   }
}
